var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex justify-content-between align-items-center h-100",
      attrs: { id: _vm.id },
    },
    [
      _c(
        "div",
        _vm._l(_vm.thirdpartyConsent, function (consent, affiliate) {
          return _c(
            "span",
            { key: affiliate, staticClass: "mr-2" },
            [
              _c("b-badge", { attrs: { variant: "primary" } }, [
                _vm._v(" " + _vm._s(`${affiliate} : ${consent}`) + " "),
              ]),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }