<template>
  <div class="users">

    <div id="user-count-stats" class="d-flex justify-content-end align-items-center my-4">
      <b-button variant="primary" @click="exportUsers">
        <Icon icon="export"/>
       <span class="ml-2">Export Users</span>
      </b-button>
    </div>



    <div class="mt-4">
      <b-table hover striped :items="items" :fields="fields" :per-page="perPage" :current-page="currentPage">

        <template v-slot:cell(edit_user)="row">
          <div class="d-flex justify-content-center">
            <b-button size="sm" variant="primary" @click="setupEditor(row)" class="mr-2">
              <Icon icon="edit"/>
            </b-button>
          </div>
        </template>

        <template v-slot:row-details="row">
          <b-container>
            <form autocomplete="off">
              <input autocomplete="false" name="hidden" type="text" style="display:none;">
              <b-form-group label="ID" label-for="id-input" horizontal :label-cols="2">
                <id-input id="id-input" :value="row.item.id"/>
              </b-form-group>
              <b-form-group label="Username" label-for="username-input" horizontal :label-cols="2">
                <b-form-input id="username-input" :value="row.item.username" @input="checkUsername($event, row.item)" :state="usernameState" role="presentation" autocomplete="off"></b-form-input>
              </b-form-group>
              <b-form-group label="Country" label-for="users-select-geo" horizontal :label-cols="2">
                <b-form-select id="users-select-geo" v-model="row.item.country" :options="geoOptions"></b-form-select>
              </b-form-group>
              <b-form-group v-if="$config.hasAdultCheck" label="Is Adult" label-for="is-adult" horizontal :label-cols="2">
                <b-form-checkbox switch v-model="row.item.is_adult"></b-form-checkbox>
              </b-form-group>
              <b-form-group v-if="row.item.type == 'partner'" label="Partner" label-for="users-select-partner" horizontal :label-cols="2">
                <b-form-select id="users-select-partner" v-model="row.item.partner_id" :options="partnerOptions"></b-form-select>
              </b-form-group>
              <b-form-group v-if="row.item.type == 'partner'" label="Allow Dashboard login" label-for="users-allow-dashboard" horizontal :label-cols="2">
                <b-form-checkbox id="users-allow-dashboard" v-model="allowPartnerDashboard"></b-form-checkbox>
              </b-form-group>
<!--
              <b-form-group label="Set New Password" label-for="password-input" horizontal :label-cols="2">
                <b-input-group>
                  <b-form-input id="password-input" autocomplete="new-password" data-lpignore="true" :type="showPassword ? 'text' : 'password'" v-model="row.item.password" ></b-form-input>
                  <b-input-group-append>
                    <b-button :pressed.sync="showPassword">
                      <font-awesome-icon :icon="showPassword ? 'eye-slash' : 'eye'"/>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
-->
              <b-form-group v-if="Object.keys(row.item.thirdparty_consent).length > 0" label="Third Party Consent" label-for="users-thirdparty-consent" horizontal :label-cols="2">
                <UserThirdPartyConsent id="users-thirdparty-consent" v-model="row.item.thirdparty_consent" :user="row.item"></UserThirdPartyConsent>
              </b-form-group>
              <b-form-group label="Mailing List Subscriptions" label-for="users-list-subscriptions" horizontal :label-cols="2">
                <UserListSubscriptions id="users-list-subscriptions" v-model="row.item.list_subscriptions" :user="row.item"></UserListSubscriptions>
              </b-form-group>
              <b-form-group label="Actions" label-for="actions-bar" horizontal :label-cols="2">
                <b-button-group id="actions-bar">
                  <b-button @click="saveModifications(row.item)" variant="primary">
                    <Icon icon="Save"/>
                    <span class="ml-2">Save Modifications</span>
                  </b-button>
                  <b-button @click="sendPasswordResetMail(row.item.id)" variant="primary">
                    <font-awesome-icon icon="at"/>
                    <span> Send Password reset mail</span>
                  </b-button>
                  <b-button v-if="row.item.type != 'customer' && $config.use2FA" id="2fa-info-button" @click="send2FAInfo(row.item.username)" variant="primary">
                    <font-awesome-icon icon="at"/>
                    Send 2FA info mail
                  </b-button>
                  <b-button v-if="row.item.status != 'locked'" @click="lockUser(row.item.id)" variant="danger">
                    <font-awesome-icon icon="ban"/>
                    <span> Lock Account</span>
                  </b-button>
                  <b-button v-if="row.item.status != 'subscribed' && row.item.subscription_offer != null" @click="reactivateUser(row.item.id)" variant="primary">
                    <font-awesome-icon icon="door-open"/>
                    <span> Reactivate</span>
                  </b-button>
                  <b-button @click="showWatchedItems(row.item.id)" variant="primary">
                    <font-awesome-icon icon="search"/>
                    <span> Show Watched Items</span>
                  </b-button>
                  <b-button @click="showOrders(row.item.id)" variant="primary">
                    <font-awesome-icon icon="search"/>
                    <span> Show Order Overview</span>
                  </b-button>
                </b-button-group>
              </b-form-group>
            </form>
          </b-container>

        </template>

      </b-table>
    </div>

    <div v-if="perPage" class="d-flex justify-content-center">
      <b-pagination :total-rows="items.length" :per-page="perPage" v-model="currentPage"/>
    </div>

    <AddUser ref="addUser"/>

    <CustomerOrders ref="customerOrders"/>

    <b-modal hide-footer size="lg" v-model="showContentModal">
      <WatchedItemsTable :url="watchedItemsUrl"/>
    </b-modal>

    <iframe id="export-users-frame" style="display:none;"></iframe>

  </div>
</template>

<script>
import IdInput from '../components/IdInput.vue'
import AddUser from '../components/AddUser.vue'
import WatchedItemsTable from '../components/WatchedItemsTable.vue'
import CustomerOrders from '../components/CustomerOrders.vue'
import UserListSubscriptions from '../components/UserListSubscriptions.vue'
import UserThirdPartyConsent from '../components/UserThirdPartyConsent.vue'

export default {
  name: 'Users',

  components: {
    IdInput,
    AddUser,
    WatchedItemsTable,
    CustomerOrders,
    UserListSubscriptions,
    UserThirdPartyConsent
  },

  data() {
    return {
      currentPage: 1,
      perPage: 20,
      nameFilter: null,
      typeFilter: 'customer',
      statusFilter: 'subscribed',
      countryFilter: '*',
      showPassword: false,
      geoOptions: [],
      partnerOptions: [],
      allowPartnerDashboard: false,

      showContentModal: false,
      watchedItemsUrl: null,
      usernameState: null,

      users: [],

      fields: [
        {key: 'username', label: 'Email', sortable: true},
        {key: 'type', sortable: true},
        {key: 'org', sortable: true},
        {key: 'status', sortable: true},
        {key: 'subscription_offer', label: 'Offer', sortable: true},
        {key: 'subscription_start', label: 'Start', sortable: true, formatter: this.$helpers.dtFormatter},
        {key: 'subscription_end', label: 'End', sortable: true, formatter: this.$helpers.dtFormatter},
        {key: 'subscription_canceled_at', label: 'Canceled', sortable: true, formatter: this.$helpers.dtFormatter},
        {key: 'country', sortable: true, formatter: this.$store.getters.countryFormatter},
        {key: 'is_paying_customer', label: 'Paying?', sortable: true, formatter: this.$helpers.booleanFormatter},
        {key: 'email_subscription', label: "Newsletter?", sortable: true, formatter: this.$helpers.booleanFormatter},
        {key: 'edit_user', label: 'Edit'}
      ],


    };
  },

  props: {

  },

  computed: {
    items() {
      return this.users.filter(item => {
        return (this.typeFilter == '*' || item.type == this.typeFilter) &&
          (this.statusFilter == '*' || item.status == this.statusFilter) &&
          (this.countryFilter == '*' || item.country == this.countryFilter) &&
          (!this.nameFilter || (item.username && item.username.match(this.nameFilter)) || (item.org && item.org.match(this.nameFilter)))
      })
    },

  },

  methods: {
    load() {
      fetch('/admin/api/v2/users/geo-options')
      .then(function(resp) {
        return resp.json();
      })
      .then(resp => {
        this.geoOptions = resp.data
        fetch('/admin/api/v2/partners/select-options')
        .then(function(resp) {
          return resp.json();
        })
        .then(resp => {
          this.partnerOptions = resp.data
          fetch('/admin/api/v2/users')
          .then(resp => {
            if(resp.status == 200) return resp.json()
            else throw Error(resp.statusText)
          })
          .then(resp => {
            this.users = resp.data
          })
          .catch(() => {
            this.$root.app.showAlert('Error loading users', 'danger')
          })
        })
      })
    },

    checkUsername(username, item) {
      username = username || ""
      username = username.trim()
      if(username == "") {
        this.usernameState = null
      }
      else {
        fetch(`/admin/api/v2/users/is-user/${username}`)
        .then(resp => {
          if(resp.status == 200) this.usernameState = (username == item.username)
          else {
            this.usernameState = this.$helpers.isValidEmail(username)
          }
        })
      }

      item.username = username
    },

    setupEditor(row) {
      this.allowPartnerDashboard = (row.item.partner_type == 'admin')
      row.toggleDetails()
    },

    saveModifications(item) {
      item.partner_type = (item.type == 'partner' ? (this.allowPartnerDashboard ? 'admin' : 'user') : null)

      fetch(`/admin/api/v2/users/${item.id}`, {method: 'PUT', body: JSON.stringify({data: item})})
      .then(resp => {
        if(resp.status == 200) {
          this.load()
          return resp.json()
        }
        else if(resp.status == 400) {
          return resp.json()
        }
        else throw Error(resp.statusText)
      })
      .then((json) => {
        if(json.status == "ok") {
          this.$root.app.showAlert('User successfully updated', 'success')
        }
        else if(json.status == "error") {

          switch(json.type) {
            case "username_already_exists":
              this.$root.app.showErrorDialog("Username already exists. Please pick a unique username.", "Error")
              break;

            case "invalid_password":
              this.$root.app.showErrorDialog("Invalid password. Please pick a password of at least 8 characters that contains no whitespace.", "Error")
              break;
          }
        }
        else throw Error()
      })
      .catch(() => {
        this.$root.app.showAlert('Error updating user', 'danger')
      })
    },

    sendPasswordResetMail(id) {
      fetch(`/admin/api/v2/users/${id}/password-reset`, {method: 'POST'})
      .then(resp => {
        if(resp.status == 200) {
          this.$root.app.showAlert('Password reset mail has been send', 'success')
        }
        else throw Error(resp.statusText)
      })
      .catch(() => {
        this.$root.app.showAlert('Error sending password reset mail', 'danger')
      })
    },

    lockUser(id) {
      fetch(`/admin/api/v2/users/${id}/lock`, {method: 'POST'})
      .then(resp => {
        if(resp.status == 200) {
          this.$root.app.showAlert('User is locked', 'success')
          this.load()
        }
        else throw Error(resp.statusText)
      })
      .catch(() => {
        this.$root.app.showAlert('Error locking user', 'danger')
      })
    },

    reactivateUser(id) {
      fetch(`/admin/api/v2/users/${id}/reactivate`, {method: 'POST'})
      .then(resp => {
        if(resp.status == 200) {
          this.$root.app.showAlert('User is reactivated', 'success')
          this.load()
        }
        else throw Error(resp.statusText)
      })
      .catch(() => {
        this.$root.app.showAlert('Error reactivating user', 'danger')
      })
    },

    showWatchedItems(id) {
      this.watchedItemsUrl = `/admin/api/v2/users/${id}/watched-items`
      this.showContentModal = true
    },

    showOrders(id) {
      this.$refs.customerOrders.show(id)
    },

    setTypeFilter(type) {
      this.typeFilter = type
    },

    setStatusFilter(status) {
      this.statusFilter = status
    },

    setCountryFilter(country) {
      this.countryFilter = country
    },

    setNameFilter(name) {
      name = name.trim() == "" ? null : new RegExp(name, "gi")
      this.nameFilter = name
    },

    addUser() {
      this.$refs.addUser.show()
    },

    handleFiltered(items) {
      this.currentPage = 1
      this.totalRows = items.length
    },

    exportUsers() {
      this.$http.postJSON(
        "/admin/api/v2/users/build-export",
        {},
        (resp) => {
          let file_name = "/admin/api/v2/users/download-export/" + resp.data;
          document.getElementById('export-users-frame').src = file_name;
        },
        (status, html) => {
          this.clear()
          this.$root.app.showErrorDialog(html, status.toString())
        }
      )
    },

    send2FAInfo(email) {
      let payload = JSON.stringify({
        email: email
      })

      fetch('/admin/api/v2/admin/2fa-request', {method: 'POST', body: payload})
      .then(resp => {
        if(resp.status != 200) {
         this.$root.app.showAlert("User not found", "danger")
        }
        else {
          this.$root.app.showAlert("2-step verification instructions sent by email", "success")
        }
      })

    }

  },

  mounted() {
    this.$root.app.setActivePageTitle("Users", false)
    this.$root.$emit('set-toolbar', 'user-toolbar', 'user')

    this.load()
  },

  created() {
    this.$root.$on('user-filter', this.setNameFilter)
    this.$root.$on('user-type-filter', this.setTypeFilter)
    this.$root.$on('user-status-filter', this.setStatusFilter)
    this.$root.$on('user-geo-filter', this.setCountryFilter)
    this.$root.$on('user-add', this.addUser)
    this.$root.$on('user-refresh', this.load)

  },

  beforeDestroy() {
    this.$root.$off('user-filter', this.setNameFilter)
    this.$root.$off('user-type-filter', this.setTypeFilter)
    this.$root.$off('user-status-filter', this.setStatusFilter)
    this.$root.$off('user-geo-filter', this.setCountryFilter)
    this.$root.$off('user-add', this.addUser)
    this.$root.$off('user-refresh', this.load)
  }
}
</script>

<style>
.no-head {
  display: none;
}
</style>
