<template>
  <div :id="id" class="d-flex justify-content-between align-items-center h-100">
    <div>
      <span v-for="(consent, affiliate) in thirdpartyConsent" :key="affiliate" class="mr-2">
        <b-badge variant="primary">
          {{ `${affiliate} : ${consent}` }}
        </b-badge>          
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserThirdPartyConsent',

  data: function() {
    return {
      thirdpartyConsent: []
    };
  },

  watch: {
    value(v) {
      if(v != this.thirdpartyConsent) {
        this.thirdpartyConsent = v
      }
    }
  },

  props: {
    value: Array,
    user: Object
  },

  methods: {
  },

  created() {
    this.thirdpartyConsent = this.value
  }
}
</script>

<style scoped>
</style>
