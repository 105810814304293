var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "edit-media mb-3",
      staticStyle: { "padding-bottom": "0.12rem" },
    },
    [
      _c(
        "div",
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { xl: "" } }, [
                _c(
                  "div",
                  { staticClass: "itemdata shadow-sm" },
                  [
                    _c(
                      "b-form",
                      { staticClass: "edit-form" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "ID",
                              "label-for": "id-input",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c("id-input", {
                              attrs: {
                                id: "id-input",
                                value: _vm.mediaEdit.id,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.mediaEdit.duplicate_of != null
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Duplicate of",
                                    "label-for": "duplicate-of-input",
                                    horizontal: "",
                                    "label-cols": 2,
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-input", {
                                        attrs: {
                                          id: "duplicate-of-input",
                                          value: _vm.mediaEdit.duplicate_of,
                                          readonly: "",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName: "v-b-tooltip.hover",
                                                  modifiers: { hover: true },
                                                },
                                              ],
                                              attrs: {
                                                title:
                                                  "Remove duplicate reference",
                                                variant: "danger",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  _vm.mediaEdit.duplicate_of =
                                                    null
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: { icon: "trash-alt" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Fallback Lang",
                              "label-for": "def-lang-input",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c("b-input", {
                              attrs: {
                                id: "def-lang-input",
                                value: _vm.defaultLanguageName,
                                readonly: "",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Source",
                              "label-for": "asset-source-input",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c("b-input", {
                              attrs: {
                                id: "asset-source-input",
                                value: _vm.mediaEdit.source,
                                readonly: "",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.media.category_type != "root"
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Partner",
                                    "label-for": "partner-select",
                                    horizontal: "",
                                    "label-cols": 2,
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      id: "partner-select",
                                      options: _vm.partnerOptions,
                                    },
                                    model: {
                                      value: _vm.itemPartner,
                                      callback: function ($$v) {
                                        _vm.itemPartner = $$v
                                      },
                                      expression: "itemPartner",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm.$config.useExternalIDs
                          ? [
                              _vm.$helpers.hasUPC(_vm.media.type)
                                ? _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "UPC",
                                        "label-for": "upc-input",
                                        horizontal: "",
                                        "label-cols": 2,
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: { id: "upc-input" },
                                        model: {
                                          value: _vm.itemUPC,
                                          callback: function ($$v) {
                                            _vm.itemUPC = $$v
                                          },
                                          expression: "itemUPC",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.$helpers.hasISRC(_vm.media.type)
                                ? _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "ISRC",
                                        "label-for": "isrc-input",
                                        horizontal: "",
                                        "label-cols": 2,
                                      },
                                    },
                                    [
                                      _c("b-form-textarea", {
                                        attrs: { id: "isrc-input" },
                                        model: {
                                          value: _vm.itemISRC,
                                          callback: function ($$v) {
                                            _vm.itemISRC = $$v
                                          },
                                          expression: "itemISRC",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.$helpers.hasISRC(_vm.media.type)
                                ? _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Reporting Code",
                                        "label-for": "reporting-code-input",
                                        horizontal: "",
                                        "label-cols": 2,
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: { id: "reporting-code-input" },
                                        model: {
                                          value: _vm.itemReportingCode,
                                          callback: function ($$v) {
                                            _vm.itemReportingCode = $$v
                                          },
                                          expression: "itemReportingCode",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _vm.media.type == "music_album" ||
                        _vm.media.type == "album_track" ||
                        _vm.$config.enableArtistsEverywhere
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Artists",
                                    "label-for": "artists-comp",
                                    horizontal: "",
                                    "label-cols": 2,
                                  },
                                },
                                [
                                  _c("LabelsInput", {
                                    attrs: {
                                      id: "artists-comp",
                                      language: _vm.mediaEdit.default_language,
                                      labels: _vm.mediaEdit.artists,
                                      "label-type": "artist",
                                    },
                                    on: { update: _vm.saveEditArtists },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm.$config.enableGenres && _vm.media.type != "category"
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Genres",
                                    "label-for": "genres-comp",
                                    horizontal: "",
                                    "label-cols": 2,
                                  },
                                },
                                [
                                  _c("LabelsInput", {
                                    attrs: {
                                      id: "genres-comp",
                                      language: _vm.mediaEdit.default_language,
                                      labels: _vm.mediaEdit.genres,
                                      "label-type": "genre",
                                    },
                                    on: { update: _vm.saveEditGenres },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm.$helpers.isPlayable(_vm.media.type) ||
                        _vm.$helpers.isTopLevel(_vm.media.type)
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Plan",
                                    "label-for": "plan-select",
                                    horizontal: "",
                                    "label-cols": 2,
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      id: "plan-select",
                                      options:
                                        _vm.$store.state.media.options.plan,
                                    },
                                    model: {
                                      value: _vm.itemPlan,
                                      callback: function ($$v) {
                                        _vm.itemPlan = $$v
                                      },
                                      expression: "itemPlan",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm.media.type != "category"
                          ? [
                              _vm.$config.usePremiumValue &&
                              _vm.$helpers.hasPremiumValue(
                                _vm.media.type,
                                _vm.itemPlan
                              ) &&
                              _vm.isPremiumType(_vm.media.type)
                                ? _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Premium Value",
                                        "label-for": "premium-value-select",
                                        horizontal: "",
                                        "label-cols": 2,
                                      },
                                    },
                                    [
                                      _c("b-form-select", {
                                        attrs: {
                                          id: "premium-value-select",
                                          options:
                                            _vm.$store.state.media.options
                                              .premiumValue,
                                        },
                                        model: {
                                          value: _vm.itemPremiumValue,
                                          callback: function ($$v) {
                                            _vm.itemPremiumValue = $$v
                                          },
                                          expression: "itemPremiumValue",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.media.type == "magazine" ||
                              _vm.media.type == "series"
                                ? _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: `${_vm.$helpers.prettify(
                                          _vm.media.type
                                        )} Layout`,
                                        "label-for": "dashboard-position",
                                        horizontal: "",
                                        "label-cols": 2,
                                      },
                                    },
                                    [
                                      _c("b-form-select", {
                                        attrs: {
                                          id: "serial-layout",
                                          options: _vm.serialLayoutOptions,
                                          switch: "",
                                        },
                                        model: {
                                          value: _vm.itemSerialLayout,
                                          callback: function ($$v) {
                                            _vm.itemSerialLayout = $$v
                                          },
                                          expression: "itemSerialLayout",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.$helpers.hasDetailPage(_vm.media.type)
                                ? _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Dashboard Pos.",
                                        "label-for": "dashboard-position",
                                        horizontal: "",
                                        "label-cols": 2,
                                      },
                                    },
                                    [
                                      _c("b-form-select", {
                                        attrs: {
                                          id: "dashboard-position",
                                          options: [
                                            { value: "top", text: "Top" },
                                            { value: "bottom", text: "Bottom" },
                                          ],
                                          switch: "",
                                        },
                                        model: {
                                          value:
                                            _vm.itemEmbeddedDashboardPosition,
                                          callback: function ($$v) {
                                            _vm.itemEmbeddedDashboardPosition =
                                              $$v
                                          },
                                          expression:
                                            "itemEmbeddedDashboardPosition",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Year of Release",
                                    "label-for": "year-input",
                                    horizontal: "",
                                    "label-cols": 2,
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "year-input",
                                      state: _vm.yearState,
                                    },
                                    model: {
                                      value: _vm.itemYear,
                                      callback: function ($$v) {
                                        _vm.itemYear = _vm._n($$v)
                                      },
                                      expression: "itemYear",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Release Date",
                                    "label-for": "release-date-input",
                                    horizontal: "",
                                    "label-cols": 2,
                                  },
                                },
                                [
                                  _c("b-calendar", {
                                    attrs: { id: "release-date-input" },
                                    model: {
                                      value: _vm.itemReleaseDate,
                                      callback: function ($$v) {
                                        _vm.itemReleaseDate = $$v
                                      },
                                      expression: "itemReleaseDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Schedule",
                                    "label-for": "publication-comp",
                                    horizontal: "",
                                    "label-cols": 2,
                                  },
                                },
                                [
                                  _c("Publication", {
                                    attrs: { id: "publication-comp" },
                                    model: {
                                      value: _vm.mediaEdit,
                                      callback: function ($$v) {
                                        _vm.mediaEdit = $$v
                                      },
                                      expression: "mediaEdit",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.$helpers.hasShoppingEnabled(_vm.media.type)
                                ? _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Enable Shopping",
                                        "label-for": "enable-shopping",
                                        horizontal: "",
                                        "label-cols": 2,
                                      },
                                    },
                                    [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          id: "enable-shopping",
                                          switch: "",
                                        },
                                        model: {
                                          value: _vm.itemShoppingEnabled,
                                          callback: function ($$v) {
                                            _vm.itemShoppingEnabled = $$v
                                          },
                                          expression: "itemShoppingEnabled",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.$helpers.isLivestreamable(_vm.media.type)
                                ? [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: "Livestream",
                                          "label-for":
                                            "datetime-livestream-comp",
                                          horizontal: "",
                                          "label-cols": 2,
                                        },
                                      },
                                      [
                                        _c("b-form-checkbox", {
                                          attrs: {
                                            id: "is-livestream-checkbox",
                                            switch: "",
                                          },
                                          model: {
                                            value: _vm.itemIsLivestream,
                                            callback: function ($$v) {
                                              _vm.itemIsLivestream = $$v
                                            },
                                            expression: "itemIsLivestream",
                                          },
                                        }),
                                        _vm.itemIsLivestream
                                          ? _c(
                                              "div",
                                              { staticClass: "mt-2" },
                                              [
                                                _c("Publication", {
                                                  attrs: {
                                                    id: "datetime-livestream-comp",
                                                    "from-field":
                                                      "datetime_livestream_start",
                                                    "to-field":
                                                      "datetime_livestream_end",
                                                  },
                                                  model: {
                                                    value: _vm.mediaEdit,
                                                    callback: function ($$v) {
                                                      _vm.mediaEdit = $$v
                                                    },
                                                    expression: "mediaEdit",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                              (_vm.$helpers.isTopLevel(_vm.media.type) ||
                                _vm.$helpers.isPlayable(_vm.media.type)) &&
                              _vm.media.type != "photo_package"
                                ? _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Labels",
                                        "label-for": "labels-comp",
                                        horizontal: "",
                                        "label-cols": 2,
                                      },
                                    },
                                    [
                                      _c("LabelsInput", {
                                        attrs: {
                                          id: "labels-comp",
                                          language:
                                            _vm.mediaEdit.default_language,
                                          labels: _vm.mediaEdit.labels,
                                        },
                                        on: { update: _vm.saveEditLabels },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.$helpers.hasRAExclude(_vm.media.type)
                                ? [
                                    _c(
                                      "b-form-group",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover",
                                            modifiers: { hover: true },
                                          },
                                        ],
                                        attrs: {
                                          label: "R.A. Exclude",
                                          "label-for": "ra-exclude-checkbox",
                                          title:
                                            "Exclude this content from the Recently Added category",
                                          horizontal: "",
                                          "label-cols": 2,
                                        },
                                      },
                                      [
                                        _c("b-form-checkbox", {
                                          attrs: {
                                            id: "ra-exclude-checkbox",
                                            switch: "",
                                          },
                                          model: {
                                            value: _vm.itemRAExclude,
                                            callback: function ($$v) {
                                              _vm.itemRAExclude = $$v
                                            },
                                            expression: "itemRAExclude",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                              _vm.$helpers.hasNoRevShare(_vm.media.type)
                                ? _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Revshare TOA",
                                        "label-for": "no-rev-share-checkbox",
                                        horizontal: "",
                                        "label-cols": 2,
                                      },
                                    },
                                    [
                                      _c("b-form-checkbox", {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover",
                                            modifiers: { hover: true },
                                          },
                                        ],
                                        attrs: {
                                          id: "no-rev-share-checkbox",
                                          title:
                                            "Puts revenue share to own account",
                                          switch: "",
                                        },
                                        model: {
                                          value: _vm.itemNoRevShare,
                                          callback: function ($$v) {
                                            _vm.itemNoRevShare = $$v
                                          },
                                          expression: "itemNoRevShare",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _vm.$helpers.hasShowMetaText(_vm.media.type)
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Show Meta Text",
                                  "label-for": "show-meta-text",
                                  horizontal: "",
                                  "label-cols": 2,
                                },
                              },
                              [
                                _c("b-form-checkbox", {
                                  attrs: { id: "show-meta-text", switch: "" },
                                  model: {
                                    value: _vm.itemShowMetaText,
                                    callback: function ($$v) {
                                      _vm.itemShowMetaText = $$v
                                    },
                                    expression: "itemShowMetaText",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.$helpers.isPlayableTopLevel(_vm.media.type)
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Kids Content",
                                  "label-for": "is-kids-content-checkbox",
                                  horizontal: "",
                                  "label-cols": 2,
                                },
                              },
                              [
                                _c("b-form-checkbox", {
                                  attrs: {
                                    id: "is-kids-content-checkbox",
                                    switch: "",
                                  },
                                  model: {
                                    value: _vm.itemIsKidsContent,
                                    callback: function ($$v) {
                                      _vm.itemIsKidsContent = $$v
                                    },
                                    expression: "itemIsKidsContent",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.$helpers.hasCoverEnabled(_vm.media.type) &&
                        _vm.itemShowMetaText
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Enable Cover",
                                  "label-for": "enable-cover",
                                  horizontal: "",
                                  "label-cols": 2,
                                },
                              },
                              [
                                _c("b-form-checkbox", {
                                  attrs: { id: "enable-cover", switch: "" },
                                  model: {
                                    value: _vm.itemCoverEnabled,
                                    callback: function ($$v) {
                                      _vm.itemCoverEnabled = $$v
                                    },
                                    expression: "itemCoverEnabled",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.$helpers.hasSlideEnabled(_vm.media.type)
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Enable Slides",
                                  "label-for": "enable-slide",
                                  horizontal: "",
                                  "label-cols": 2,
                                },
                              },
                              [
                                _c("b-form-checkbox", {
                                  attrs: { id: "enable-slide", switch: "" },
                                  model: {
                                    value: _vm.itemSlideEnabled,
                                    callback: function ($$v) {
                                      _vm.itemSlideEnabled = $$v
                                    },
                                    expression: "itemSlideEnabled",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.$helpers.hasHeaderOverflow(
                          _vm.media.type,
                          _vm.media.category_type
                        )
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Enable Header Overflow",
                                  "label-for": "enable-header-overflow",
                                  horizontal: "",
                                  "label-cols": 2,
                                },
                              },
                              [
                                _c("b-form-checkbox", {
                                  attrs: {
                                    id: "enable-header-overflow",
                                    switch: "",
                                  },
                                  model: {
                                    value: _vm.itemHeaderOverflowEnabled,
                                    callback: function ($$v) {
                                      _vm.itemHeaderOverflowEnabled = $$v
                                    },
                                    expression: "itemHeaderOverflowEnabled",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.$helpers.hasQualityStamp(_vm.media.type)
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Quality Stamp",
                                  "label-for": "quality-stamp-checkbox",
                                  horizontal: "",
                                  "label-cols": 2,
                                },
                              },
                              [
                                _c("b-form-checkbox", {
                                  attrs: {
                                    id: "quality-stamp-checkbox",
                                    switch: "",
                                  },
                                  model: {
                                    value: _vm.itemQualityStamp,
                                    callback: function ($$v) {
                                      _vm.itemQualityStamp = $$v
                                    },
                                    expression: "itemQualityStamp",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.$helpers.isTopLevel(_vm.media.type)
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Geo Whitelist",
                                    "label-for": "whitelist-comp",
                                    horizontal: "",
                                    "label-cols": 2,
                                  },
                                },
                                [
                                  _c("Geos", {
                                    attrs: {
                                      id: "whitelist-comp",
                                      url: _vm.geosUrl,
                                    },
                                    model: {
                                      value: _vm.itemGeoWhitelist,
                                      callback: function ($$v) {
                                        _vm.itemGeoWhitelist = $$v
                                      },
                                      expression: "itemGeoWhitelist",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Geo Blackist",
                                    "label-for": "blacklist-comp",
                                    horizontal: "",
                                    "label-cols": 2,
                                  },
                                },
                                [
                                  _c("Geos", {
                                    attrs: {
                                      id: "blacklist-comp",
                                      url: _vm.geosUrl,
                                    },
                                    model: {
                                      value: _vm.itemGeoBlacklist,
                                      callback: function ($$v) {
                                        _vm.itemGeoBlacklist = $$v
                                      },
                                      expression: "itemGeoBlacklist",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        (_vm.media.type == "category" ||
                          _vm.media.type == "product_category" ||
                          _vm.media.type == "playlist_page") &&
                        _vm.media.category_type != "root" &&
                        _vm.media.category_layout != null
                          ? _c(
                              "div",
                              { staticClass: "py-2 my-3 rounded bg-category" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Enable More Page",
                                      "label-for": "enable-page",
                                      horizontal: "",
                                      "label-cols": 2,
                                    },
                                  },
                                  [
                                    _c("b-form-checkbox", {
                                      attrs: { id: "enable-page", switch: "" },
                                      model: {
                                        value: _vm.itemPageEnabled,
                                        callback: function ($$v) {
                                          _vm.itemPageEnabled = $$v
                                        },
                                        expression: "itemPageEnabled",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-start align-items-center mb-2",
                                  },
                                  [
                                    _c(
                                      "h5",
                                      { staticClass: "font-weight-bold" },
                                      [_vm._v("Category")]
                                    ),
                                  ]
                                ),
                                _vm.mediaEdit.category_type &&
                                _vm.mediaEdit.category_type != "root"
                                  ? _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: "Type",
                                          "label-for": "category-type-input",
                                          horizontal: "",
                                          "label-cols": 2,
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "category-type-input",
                                            value: _vm.$helpers.prettify(
                                              _vm.mediaEdit.category_type
                                            ),
                                            readonly: "",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.mediaEdit.type == "playlist_page"
                                  ? _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: "Asset Format",
                                          "label-for":
                                            "category-asset-format-input",
                                          horizontal: "",
                                          "label-cols": 2,
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "category-asset-format-input",
                                            value: _vm.$helpers.prettify(
                                              _vm.mediaEdit.asset_format
                                            ),
                                            readonly: "",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.media.type != "playlist_page"
                                  ? _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: "Ad Mode",
                                          "label-for":
                                            "category-ad-mode-select",
                                          horizontal: "",
                                          "label-cols": 2,
                                        },
                                      },
                                      [
                                        _c("b-form-select", {
                                          attrs: {
                                            id: "category-ad-mode-select",
                                            options: _vm.categoryAdModeOptions,
                                          },
                                          model: {
                                            value: _vm.categoryAdMode,
                                            callback: function ($$v) {
                                              _vm.categoryAdMode = $$v
                                            },
                                            expression: "categoryAdMode",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Format",
                                      "label-for": "category-format-select",
                                      horizontal: "",
                                      "label-cols": 2,
                                    },
                                  },
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        id: "category-format-select",
                                        options:
                                          _vm.categoryLayoutFormatOptions,
                                      },
                                      model: {
                                        value: _vm.categoryLayoutFormat,
                                        callback: function ($$v) {
                                          _vm.categoryLayoutFormat = $$v
                                        },
                                        expression: "categoryLayoutFormat",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Formfactor",
                                      "label-for":
                                        "category-layout-formfactor-select",
                                      horizontal: "",
                                      "label-cols": 2,
                                    },
                                  },
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        id: "category-layout-formfactor-select",
                                        options:
                                          _vm.categoryLayoutFormfactorOptions,
                                      },
                                      model: {
                                        value: _vm.categoryLayoutFormfactor,
                                        callback: function ($$v) {
                                          _vm.categoryLayoutFormfactor = $$v
                                        },
                                        expression: "categoryLayoutFormfactor",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Size",
                                      "label-for":
                                        "category-layout-size-select",
                                      horizontal: "",
                                      "label-cols": 2,
                                    },
                                  },
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        id: "category-layout-size-select",
                                        options: _vm.categoryLayoutSizeOptions,
                                      },
                                      model: {
                                        value: _vm.categoryLayoutSize,
                                        callback: function ($$v) {
                                          _vm.categoryLayoutSize = $$v
                                        },
                                        expression: "categoryLayoutSize",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Show Titles",
                                      "label-for": "category-layout-show-title",
                                      horizontal: "",
                                      "label-cols": 2,
                                    },
                                  },
                                  [
                                    _c("b-form-checkbox", {
                                      attrs: {
                                        id: "category-layout-show-title",
                                        switch: "",
                                      },
                                      model: {
                                        value: _vm.categoryLayoutShowTitles,
                                        callback: function ($$v) {
                                          _vm.categoryLayoutShowTitles = $$v
                                        },
                                        expression: "categoryLayoutShowTitles",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                !_vm.disableShowOverlay
                                  ? _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: "Overlay visible",
                                          "label-for":
                                            "category-layout-show-overlay",
                                          horizontal: "",
                                          "label-cols": 2,
                                        },
                                      },
                                      [
                                        _c("b-form-checkbox", {
                                          attrs: {
                                            id: "category-layout-show-overlay",
                                            switch: "",
                                          },
                                          model: {
                                            value:
                                              _vm.categoryLayoutShowOverlay,
                                            callback: function ($$v) {
                                              _vm.categoryLayoutShowOverlay =
                                                $$v
                                            },
                                            expression:
                                              "categoryLayoutShowOverlay",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.media.type == "video" || _vm.media.type == "episode"
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Subtitles",
                                  "label-for": "subtitle-list",
                                  horizontal: "",
                                  "label-cols": 2,
                                },
                              },
                              [
                                _c("SubtitleList", {
                                  attrs: {
                                    id: "subtitle-list",
                                    subtitles: _vm.mediaEdit.subtitles,
                                    "item-id": _vm.mediaEdit.id,
                                  },
                                  on: {
                                    reload: function ($event) {
                                      return _vm.tryReload(4000)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.$helpers.hasAssets(_vm.mediaEdit.type)
                          ? [
                              _vm.$helpers.isExternalAsset(
                                _vm.mediaEdit.asset_format
                              )
                                ? _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Assets",
                                        "label-for": "asset-list",
                                        horizontal: "",
                                        "label-cols": 2,
                                      },
                                    },
                                    [
                                      _c("EmbeddedAsset", {
                                        attrs: {
                                          id: "asset-list",
                                          "media-item-id": _vm.mediaEdit.id,
                                          assets: _vm.mediaEdit.assets,
                                        },
                                        on: {
                                          "delete-asset": function ($event) {
                                            return _vm.deleteAsset($event)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm.$helpers.isInternalAsset(
                                    _vm.mediaEdit.asset_format
                                  ) && _vm.mediaEdit.assets
                                ? _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Assets",
                                        "label-for": "asset-list",
                                        horizontal: "",
                                        "label-cols": 2,
                                      },
                                    },
                                    [
                                      _c("AssetList", {
                                        attrs: {
                                          id: "asset-list",
                                          items: _vm.mediaEdit.assets,
                                          "item-id": _vm.mediaEdit.id,
                                          format: _vm.mediaEdit.asset_format,
                                          language: _vm.selectedLanguage,
                                        },
                                        on: {
                                          "delete-asset": function ($event) {
                                            return _vm.deleteAsset($event)
                                          },
                                          "retranscode-asset": function (
                                            $event
                                          ) {
                                            return _vm.retranscodeAsset($event)
                                          },
                                          update: function ($event) {
                                            return _vm.tryReload(4000)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _vm.hasThumbnails
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Thumbnails",
                                  "label-for": "thumbnails-list",
                                  horizontal: "",
                                  "label-cols": 2,
                                },
                              },
                              [
                                _c("ImageList", {
                                  attrs: {
                                    id: "thumbnails-list",
                                    "item-id": _vm.mediaEdit.id,
                                    "item-type": _vm.mediaEdit.type,
                                    language: _vm.selectedLanguage,
                                    type: "thumbnail",
                                  },
                                  on: {
                                    reload: function ($event) {
                                      return _vm.tryReload(4000)
                                    },
                                  },
                                  model: {
                                    value: _vm.mediaEdit.thumbnails,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.mediaEdit, "thumbnails", $$v)
                                    },
                                    expression: "mediaEdit.thumbnails",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.mediaEdit.preview_url ||
                        _vm.mediaEdit.type == "category" ||
                        _vm.mediaEdit.type == "page" ||
                        _vm.mediaEdit.type == "artist_page" ||
                        _vm.mediaEdit.type == "playlist_page"
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Preview url",
                                  "label-for": "preview-url-input",
                                  horizontal: "",
                                  "label-cols": 2,
                                },
                              },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("b-input", {
                                      attrs: {
                                        id: "preview-url-input",
                                        value: _vm.mediaEdit.preview_url,
                                        type: "text",
                                        readonly: "",
                                      },
                                    }),
                                    _c(
                                      "b-input-group-append",
                                      [
                                        _vm.mediaEdit.type == "category" ||
                                        _vm.mediaEdit.type == "page" ||
                                        _vm.mediaEdit.type == "artist_page" ||
                                        _vm.mediaEdit.type == "playlist_page"
                                          ? _c(
                                              "b-button",
                                              {
                                                attrs: { variant: "primary" },
                                                on: {
                                                  click: _vm.selectPreviewUrl,
                                                },
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  attrs: { icon: "search" },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "b-button",
                                          {
                                            attrs: { variant: "danger" },
                                            on: {
                                              click: function ($event) {
                                                _vm.mediaEdit.preview_url = null
                                              },
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: "trash-alt" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "b-col",
                { attrs: { xl: "" } },
                [
                  _c("b-form", { staticClass: "edit-form shadow-sm" }, [
                    _c(
                      "div",
                      { staticClass: "metadata" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row-reverse align-items-center edit-actions",
                          },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true },
                                  },
                                ],
                                staticClass: "mx-1",
                                attrs: { variant: "danger", title: "Close" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.$emit("close", _vm.mediaId)
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "times" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true },
                                  },
                                ],
                                staticClass: "mx-1",
                                attrs: { variant: "primary", title: "Save" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("save", {
                                      id: _vm.mediaId,
                                    })
                                  },
                                },
                              },
                              [_c("Icon", { attrs: { icon: "Save" } })],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Metadata Language",
                              "label-for": "language-select",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center justify-content-between",
                              },
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    id: "language-select",
                                    options:
                                      _vm.$store.state.media.options.language,
                                  },
                                  model: {
                                    value: _vm.selectedLanguage,
                                    callback: function ($$v) {
                                      _vm.selectedLanguage = $$v
                                    },
                                    expression: "selectedLanguage",
                                  },
                                }),
                                _vm.selectedLanguage !=
                                _vm.mediaEdit.default_language
                                  ? _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover",
                                            modifiers: { hover: true },
                                          },
                                        ],
                                        attrs: {
                                          title:
                                            "Permanently delete meta data for selected language",
                                          variant: "danger",
                                        },
                                        on: { click: _vm.deleteMeta },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "trash-alt" },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Title",
                              "label-for": "title-input",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "title-input",
                                state: _vm.titleState,
                              },
                              model: {
                                value: _vm.metaTitle,
                                callback: function ($$v) {
                                  _vm.metaTitle = $$v
                                },
                                expression: "metaTitle",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Slug",
                              "label-for": "slug-input",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c(
                              "b-input-group",
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "slug-input",
                                    state: _vm.slugState,
                                  },
                                  model: {
                                    value: _vm.metaSlug,
                                    callback: function ($$v) {
                                      _vm.metaSlug = $$v
                                    },
                                    expression: "metaSlug",
                                  },
                                }),
                                _c(
                                  "b-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.makeSlugFromTitle.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("From Title")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Secondary Title",
                              "label-for": "sec-title-input",
                              horizontal: "",
                              "label-cols": 2,
                            },
                          },
                          [
                            _c(
                              "b-input-group",
                              [
                                _c("b-form-input", {
                                  attrs: { id: "sec-title-input" },
                                  model: {
                                    value: _vm.metaSecTitle,
                                    callback: function ($$v) {
                                      _vm.metaSecTitle = $$v
                                    },
                                    expression: "metaSecTitle",
                                  },
                                }),
                                _vm.$config.enableArtistsEverywhere
                                  ? _c(
                                      "b-button",
                                      {
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.makeSecTitleFromArtists.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("From Artists")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.mediaEdit.show_meta_text &&
                        _vm.$helpers.hasFullMeta(_vm.media.type)
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Short Description",
                                    "label-for": "short-desc-input",
                                    horizontal: "",
                                    "label-cols": 2,
                                  },
                                },
                                [
                                  _c("b-form-textarea", {
                                    attrs: { id: "short-desc-textarea" },
                                    model: {
                                      value: _vm.metaShortDescription,
                                      callback: function ($$v) {
                                        _vm.metaShortDescription = $$v
                                      },
                                      expression: "metaShortDescription",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Long Description",
                                    "label-for": "long-desc-textarea",
                                    horizontal: "",
                                    "label-cols": 2,
                                  },
                                },
                                [
                                  _c("b-form-textarea", {
                                    staticStyle: { height: "228px" },
                                    attrs: { id: "long-desc-textarea" },
                                    model: {
                                      value: _vm.metaLongDescription,
                                      callback: function ($$v) {
                                        _vm.metaLongDescription = $$v
                                      },
                                      expression: "metaLongDescription",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Credits",
                                    "label-for": "credits-edit",
                                    horizontal: "",
                                    "label-cols": 2,
                                  },
                                },
                                [
                                  _c("Credits", {
                                    attrs: { id: "credits-edit" },
                                    model: {
                                      value: _vm.itemCredits,
                                      callback: function ($$v) {
                                        _vm.itemCredits = $$v
                                      },
                                      expression: "itemCredits",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm.$helpers.hasCallToAction(_vm.media.type)
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Call To Action",
                                    horizontal: "",
                                    "label-cols": 2,
                                  },
                                },
                                [
                                  _c("b-form-checkbox", {
                                    attrs: {
                                      id: "c2a-enable-checkbox",
                                      switch: "",
                                    },
                                    model: {
                                      value:
                                        _vm.mediaEdit.call_to_action_enabled,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.mediaEdit,
                                          "call_to_action_enabled",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "mediaEdit.call_to_action_enabled",
                                    },
                                  }),
                                  _vm.mediaEdit.call_to_action_enabled
                                    ? _c(
                                        "div",
                                        { staticClass: "mt-2" },
                                        [
                                          _c("SimpleAutoComplete", {
                                            attrs: { items: _vm.c2aItems },
                                            model: {
                                              value: _vm.metaCallToAction,
                                              callback: function ($$v) {
                                                _vm.metaCallToAction = $$v
                                              },
                                              expression: "metaCallToAction",
                                            },
                                          }),
                                          _c("b-form-input", {
                                            staticClass: "mt-1",
                                            attrs: {
                                              id: "c2a-link-input",
                                              state: _vm.c2aUrlState,
                                              placeholder:
                                                "URL (starting with https://)",
                                            },
                                            model: {
                                              value: _vm.metaCallToActionURL,
                                              callback: function ($$v) {
                                                _vm.metaCallToActionURL = $$v
                                              },
                                              expression: "metaCallToActionURL",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          : _vm.media.type == "external"
                          ? [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Link to",
                                    "label-for": "link-input",
                                    horizontal: "",
                                    "label-cols": 2,
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "link-input",
                                      placeholder:
                                        "URL (starting with https://)",
                                    },
                                    model: {
                                      value: _vm.metaLinkTo,
                                      callback: function ($$v) {
                                        _vm.metaLinkTo = $$v
                                      },
                                      expression: "metaLinkTo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        !(
                          _vm.media.type == "year" ||
                          _vm.media.type == "season" ||
                          _vm.media.type == "photo_package"
                        )
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Search Tags",
                                  "label-for": "search-tags-textarea",
                                  horizontal: "",
                                  "label-cols": 2,
                                },
                              },
                              [
                                _c("b-form-textarea", {
                                  attrs: { id: "search-tags-textarea" },
                                  model: {
                                    value: _vm.metaSearchTags,
                                    callback: function ($$v) {
                                      _vm.metaSearchTags = $$v
                                    },
                                    expression: "metaSearchTags",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.hasCovers
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Covers",
                                  "label-for": "covers-list",
                                  horizontal: "",
                                  "label-cols": 2,
                                },
                              },
                              [
                                _c("ImageList", {
                                  attrs: {
                                    id: "covers-list",
                                    "item-id": _vm.mediaEdit.id,
                                    "item-type": _vm.mediaEdit.type,
                                    language: _vm.selectedLanguage,
                                    type: "cover",
                                    "allow-copy-if-empty":
                                      Object.values(
                                        _vm.$store.state.app.languages
                                      ).length > 1,
                                  },
                                  on: {
                                    "copy-from": _vm.copyCoversFrom,
                                    reload: function ($event) {
                                      return _vm.tryReload(4000)
                                    },
                                  },
                                  model: {
                                    value: _vm.metaCovers,
                                    callback: function ($$v) {
                                      _vm.metaCovers = $$v
                                    },
                                    expression: "metaCovers",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.hasSlides
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Slides",
                                  "label-for": "slides-list",
                                  horizontal: "",
                                  "label-cols": 2,
                                },
                              },
                              [
                                _c("ImageList", {
                                  attrs: {
                                    id: "slides-list",
                                    "item-id": _vm.mediaEdit.id,
                                    "item-type": _vm.mediaEdit.type,
                                    language: _vm.selectedLanguage,
                                    type: "slide",
                                    "allow-copy-if-empty":
                                      Object.values(
                                        _vm.$store.state.app.languages
                                      ).length > 1,
                                    "allow-link-to":
                                      !_vm.mediaEdit.call_to_action_enabled,
                                  },
                                  on: {
                                    "copy-from": _vm.copyCoversFrom,
                                    reload: function ($event) {
                                      return _vm.tryReload(4000)
                                    },
                                  },
                                  model: {
                                    value: _vm.metaSlides,
                                    callback: function ($$v) {
                                      _vm.metaSlides = $$v
                                    },
                                    expression: "metaSlides",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.media.type == "category" || _vm.media.type == "playlist_page"
        ? [
            _vm.media.category_type == "dynamic"
              ? _c("EditDynamicCategory", {
                  attrs: {
                    language: _vm.media.default_language,
                    subject: _vm.media.id,
                    parent: _vm.media.parent,
                    format: (_vm.mediaEdit.category_layout || {}).format,
                    type: _vm.media.type,
                    "asset-format": _vm.media.asset_format,
                  },
                  on: { update: _vm.saveEditCategoryFilter },
                })
              : _vm._e(),
            _vm.media.category_type == "manual"
              ? _c("EditManualCategory", {
                  attrs: {
                    items: _vm.categoryItems,
                    format: _vm.categoryLayoutFormat,
                    lang: _vm.media.default_language,
                    "asset-format": _vm.media.asset_format,
                  },
                  on: { update: _vm.saveEditManualItems },
                })
              : _vm._e(),
            _vm.media.category_type == "recently-added"
              ? _c("RecentlyAddedCategoyContent", {
                  attrs: {
                    language: _vm.media.default_language,
                    subject: _vm.media.id,
                  },
                })
              : _vm._e(),
            _vm.media.category_type == "top-artist"
              ? _c("ContentTable", { attrs: { items: _vm.categoryContent } })
              : _vm._e(),
          ]
        : _vm._e(),
      _c("SimpleModal", {
        attrs: {
          value: _vm.deleteMetaModalVisible,
          msg: "Are you sure you want to permanently delete all meta data for the selected language?",
        },
        on: {
          ok: _vm.doDeleteMeta,
          cancel: function ($event) {
            _vm.deleteMetaModalVisible = false
          },
        },
      }),
      _vm.media.type == "category" ||
      _vm.media.type == "page" ||
      _vm.media.type == "artist_page" ||
      _vm.media.type == "playlist_page"
        ? [
            _c("SelectItem", {
              attrs: {
                title: "Select Preview Video",
                lang: _vm.media.default_language,
                types: "video,episode",
                "require-preview": "",
              },
              on: {
                selected: function ($event) {
                  _vm.mediaEdit.preview_url = $event.preview_url
                },
              },
              model: {
                value: _vm.selectPreviewUrlVisible,
                callback: function ($$v) {
                  _vm.selectPreviewUrlVisible = $$v
                },
                expression: "selectPreviewUrlVisible",
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }